<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :filters.sync="filters"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.MENU_LIST"
        @resetAllSearch="onResetAllSearch"
        @searched="onSearch"
      >

        <template v-slot:filters>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group">
                <label class="align-self-center mb-0 mr-5 pl-2">{{ filters.status_enabled_equal.label }}</label>
                <div class="col-xl-12 col-lg-12 col-sm-12 gr-icheck">
                  <app-radio v-for="(item, index) in MENU_CONSTANT.FILTER_STATUS"
                             :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                             :val="item.id" v-model="filters.status_enabled_equal.value"/>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.apartment_id_equal.label }}</label>
                <div class="col-xl-9 col-lg-9 col-sm-9">
                   <app-select :name="filters.apartment_id_equal.name"
                                input-style="normal"
                                v-model="filters.apartment_id_equal.value"
                                :options-data="meta.apartments" />
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group">
                <label>{{ filters.category_id_equal.label }}</label>
                <div class="col-xl-9 col-lg-9 col-sm-9">
                  <app-select :name="filters.category_id_equal.name"
                              input-style="normal"
                              v-model="filters.category_id_equal.value"
                              :options-data="meta.categories" />
                </div>
              </div>
            </div>
<!--            <div class="col-xl-4 col-lg-4 col-sm-4">-->
<!--              <div class="form-group">-->
<!--                <label>{{ filters.apartment_id_equal.label }}</label>-->
<!--                <app-input :name="filters.serial_id_like.name" input-style="normal"-->
<!--                           v-model="filters.serial_id_like.value"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->

          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="col-xl-9 col-lg-9 col-sm-9">
                <div class="form-group">
                  <label>{{ filters.name_like.label }}</label>
                  <app-input :name="filters.name_like.name" input-style="normal"
                             v-model="filters.name_like.value"
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group start_date">
                <label class="col-xl-4 col-lg-6 col-sm-8">{{ filters.price_min.label }}</label>
                <div class="d-flex justify-content-between">
                  <div class="col-xl-9 col-lg-12 col-sm-12">
                    <app-input type="digits" :rules="`price_min:${filters.price_max.value}`" :name="filters.price_min.name" input-style="normal"
                               v-model="filters.price_min.value"/>
                  </div>
                  <span class="align-self-center align-items-center mr-lg-2 mr-xl-5 " style="font-size: 30px">~</span>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-sm-4">
              <div class="form-group">
                <label class="col-xl-4 col-lg-4 col-sm-8" style="opacity: 0">1</label>
                <div class="col-xl-9 col-lg-12 col-sm-12">
                  <app-input type="digits" :name="filters.price_max.name" input-style="normal"
                             v-model="filters.price_max.value"/>
                </div>
              </div>
            </div>
          </div>
        </template>


        <template v-slot:table-menu-right>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("common.create") }}
          </button>
        </template>

        <template v-slot:body-cell-edit="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              @click="handleBtnUpdateClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("common.edit") }}
            </button>
          </td>
        </template>

        <template v-slot:body-cell-image_path="props">
          <td class="app-align-middle" style="display: flex;justify-content: center;">
            <img :src="props.row.image_path" v-bind:style="props.row.image_path ? '' : 'opacity:0'" style="width: 100px; height: 100px">
          </td>
        </template>

        <template v-slot:body-cell-status_enabled="props">
          <td class="app-align-middle">
            <p class=" app-table-p app-cell-tooltip mb-0">
              {{getStatus(props.row.status_enabled)}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-tax="props">
          <td class="app-align-middle">
            <p  class="app-table-p app-cell-tooltip mb-0 text-right">
              {{convertCurrent(props.row.tax_list.replace('¥', ''))}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-category_id="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.category_name" style="color: #007bff;"
               @click="handlerCategoryNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.category_name}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-apartment_id="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.apartment_name" style="color: #007bff;"
               @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.apartment_name}}
            </p>
          </td>
        </template>

        <template v-slot:body-cell-price="props">
          <td class="app-align-middle">
            <p  class="app-table-p app-cell-tooltip mb-0 text-right">
              {{convertCurrent(props.row.price_list.replace('¥', ''))}}
            </p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  import {RESERVATION_CONSTANT} from "../../../constants/reservation";
  import {MENU_CONSTANT} from "../../../constants/menu";
  export default {
    name: "MenuListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          status_enabled_equal: {
            name: "status_enabled",
            condition: "equal",
            label: this.$t("menu.status")
          },
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("menu.mansion")
          },
          category_id_equal: {
            name: "category_id",
            condition: "equal",
            label: this.$t("menu.category")
          },
          name_like: {
            name: "name_like",
            condition: "like",
            label: this.$t("menu.name")
          },
          price_min : {
            name : "price_min",
            condition: "between",
            label: this.$t("menu.price")
          },
          price_max : {
            name : "price_max",
            condition: "between",
            label: this.$t("menu.price")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
          {name: "status_enabled", label: this.$t("menu.status"), sortable: true, textAlign: 'text-center'},
          {name: "name", label: this.$t("menu.name"), sortable: true},
          {name: "category_id", label: this.$t("menu.category")},
          {name: "apartment_id", label: this.$t("menu.mansion"),sortable: true},
          {name: "image_path", label: this.$t("menu.image_path_list"), textAlign: 'text-center', sortable: true},
          {name: "price", label: this.$t("menu.price"),sortable: true, textAlign: 'text-center'},
          {name: "tax", label: this.$t("menu.tax"), textAlign: 'text-center'},
          {name: "is_reduced_tax_list_page", label: this.$t("menu.is_reduced_tax") , textAlign: 'text-center'},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
      };
    },
    watch : {
      "filters.apartment_id_equal.value" : function(value) {
        this.getMetaData({apartment_id : value})
      },
      "filters.price_min.value" : async function(value) {
        if (value) {
          this.filters.price_min.value = value.replace(',', '')
        }
        await this.$refs.form_create_entry.validate()
      },
      "filters.price_max.value" : async function(value) {
        if (value) {
          this.filters.price_max.value = value.replace(',', '')
        }
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.MENU_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.MENU_EDIT, params: {id: entry.id}})
      },
      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      handlerCategoryNameClick(entry) {
        if (entry.category_id) {
          let routeCategory = this.$router.resolve({name: this.ROUTES.ADMIN.SERVICE_CATEGORY_EDIT, params: {id: entry.category_id}});
          window.open(routeCategory.href, '_blank');
        }
      },
      async onResetAllSearch() {
        await this.$router.push({name: this.$route.name,
          query: {
            'filters.apartment_id.equal' : 'all',
            'filters.category_id.equal' : 'all',
            'filters.status_enabled.equal' : 'all'
          }
        }).catch(e => {
          console.log(e)
        })
        await this.$refs.form_create_entry.reset()
      },
      async onSearch() {
      },
      getStatus(status) {
        let data = MENU_CONSTANT.STATUS.find(({id}) => id === status);
        return data.name
      },
      getMetaData(params) {
        this.$request.get(this.ENDPOINT.UI_HELPER_MENU, params).then(res => {
          this.meta = {
            categories : res.data.categories,
            apartments: res.data.apartments,
          }
          this.meta.apartments.unshift({
            id: "all",
            name: ""
          })
          this.meta.categories.unshift({
            id: "all",
            name: ""
          })

          let vm = this
          if (!this.meta.categories.find(({id}) => id === parseInt(vm.filters.category_id_equal.value))) {
            this.filters.category_id_equal.value = 'all';
          }
        })
      },
      convertCurrent(value) {
        return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(Number(value));
      }
    },
    mounted: function () {
      // this.getMetaData({})
    },
  }
</script>

<style>
  .description p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .start_date .error {
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 12px;
    color: #f55753;
    display: block;
    position: absolute !important;
  }
</style>
